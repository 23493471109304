var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c("app-loader"),
      _c(
        "v-row",
        {
          staticClass: "pt-12",
          attrs: { "no-gutters": "", align: "center", justify: "center" }
        },
        [_c("img", { attrs: { src: _vm.appLogo, height: "310", alt: "logo" } })]
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center", justify: "center" } },
        [_c("router-view", { staticClass: "page-wrapper" })],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center", justify: "center" } },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("views.constants.copyright", {
                  currentYear: _vm.moment(new Date()).year()
                })
              ) +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }